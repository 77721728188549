/* eslint-disable react/prop-types */
import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';

function TextInput({
  className,
  id,
  label,
  padding,
  onChange,
  clearEnabled,
  onClear,
  ...props
}) {
  const inputRef = useRef();
  const [clear, setClear] = useState(false);

  const change = (e) => {
    setClear(!!inputRef.current.value.length);
    onChange(e);
  };
  const clearInput = () => {
    inputRef.current.value = '';
    onChange({ target: { value: '' } });
    setClear(false);
  };
  return (
    <>
      {label.length > 0 && (
        <label className="sr-only" htmlFor={id}>
          {label}
        </label>
      )}
      <div className="w-full relative">
        <div className="absolute inset-y-0 left-0 flex items-center pl-[18px] md:pl-[16px] pointer-events-none">
          <svg
            className="w-[13px] md:w-[20px]"
            width="17"
            height="17"
            viewBox="0 0 17 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M6.85032 1.90224e-10C8.12453 -9.49391e-06 9.37349 0.355368 10.4568 1.02619C11.5402 1.69701 12.4149 2.65669 12.9828 3.79736C13.5507 4.93803 13.7892 6.21448 13.6715 7.48324C13.5537 8.752 13.0845 9.96279 12.3165 10.9795L16.2585 14.9225C16.4306 15.1007 16.5259 15.3394 16.5237 15.5871C16.5215 15.8349 16.4222 16.0719 16.247 16.2471C16.0718 16.4222 15.8348 16.5216 15.5871 16.5238C15.3393 16.5259 15.1006 16.4307 14.9224 16.2586L10.9794 12.3165C10.1125 12.9714 9.1023 13.4106 8.03214 13.5981C6.96197 13.7855 5.86258 13.7157 4.82472 13.3944C3.78685 13.0732 2.84029 12.5096 2.06316 11.7504C1.28603 10.9912 0.70063 10.058 0.355287 9.02787C0.00994384 7.99777 -0.0854381 6.9003 0.0770154 5.82607C0.239469 4.75183 0.655099 3.73164 1.28959 2.84972C1.92409 1.96779 2.75924 1.24943 3.72612 0.75393C4.693 0.258432 5.76387 1.07165e-05 6.85032 1.90224e-10ZM6.85032 1.88976C5.53468 1.88976 4.27292 2.4124 3.34263 3.34269C2.41233 4.27299 1.88969 5.53475 1.88969 6.85038C1.88969 8.16602 2.41233 9.42778 3.34263 10.3581C4.27292 11.2884 5.53468 11.811 6.85032 11.811C8.16596 11.811 9.42771 11.2884 10.358 10.3581C11.2883 9.42778 11.8109 8.16602 11.8109 6.85038C11.8109 5.53475 11.2883 4.27299 10.358 3.34269C9.42771 2.4124 8.16596 1.88976 6.85032 1.88976Z"
              fill="#240253"
            />
          </svg>
        </div>
        <input
          id={id}
          className={`${className} border border-stroke text-2sm rounded focus:ring-cf-green focus:border-cf-green block w-full pl-[42px] md:pl-[44px] md:h-[56px] ${
            clearEnabled ? 'pr-[24px] md:pr-[24px]' : ''
          } ${padding}`}
          type="text"
          onChange={change}
          ref={inputRef}
          {...props}
        />
        {clearEnabled && clear && (
          <button
            className="absolute right-2 top-[20px]"
            type="button"
            onClick={clearInput}
            aria-label="clear input">
            <svg
              className="svg-close"
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="none">
              <path
                stroke="#000"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.333"
                d="m12 4-8 8M4 4l8 8"
              />
            </svg>
          </button>
        )}
      </div>
    </>
  );
}
TextInput.defaultProps = {
  className: '',
  padding: 'py-[9px] px-[16px] md:p-[16px]',
  label: '',
  id: null,
  clearEnabled: false,
  onChange: () => {},
};
TextInput.propTypes = {
  className: PropTypes.string,
  padding: PropTypes.string,
  label: PropTypes.string,
  id: PropTypes.string,
  clearEnabled: PropTypes.bool,
  onChange: PropTypes.func,
};
export default TextInput;
