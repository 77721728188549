import PropTypes from 'prop-types';

export const CaseStudyCardType = PropTypes.shape({
  id: PropTypes.string,
  type: PropTypes.string,
  media: PropTypes.shape({
    srcSet: PropTypes.string,
    sizes: PropTypes.string,
    sourceUrl: PropTypes.string,
    altText: PropTypes.string,
  }),
  products: PropTypes.arrayOf(
    PropTypes.shape({
      slug: PropTypes.string,
      name: PropTypes.string,
      link: PropTypes.string,
    }),
  ),
  title: PropTypes.string,
  excerpt: PropTypes.string,
  href: PropTypes.string,
  dataType: PropTypes.string,
  background: PropTypes.string,
});

export default { CaseStudyCardType };
