import React from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';
import { RoundedTextInput } from '../Input';
import CaseStudyCard from './CaseStudyCard';
import Select from './Select';
import useCaseStudy from '../../hooks/useCaseStudy';
import { CaseStudyCardType } from '../../utils/prop-types';
import './CaseStudyExplore.scss';
// const caseStudyOptions = [
//   { label: 'All', value: '' },
//   { label: 'Case Studies', value: 'caseStudy' },
//   { label: 'Videos', value: 'video', disabled: true },
//   { label: 'Shorts', value: 'short', disabled: true },
// ];
const transform = (data, dataType) => {
  return {
    id: data.id,
    type: dataType === 'video' ? 'video' : 'image',
    dataType,
    media: {
      srcSet:
        // data?.caseStudyMeta?.featuredImage?.srcSet ||
        data.featuredImage?.node?.srcSet || '',
      sizes:
        data?.caseStudyMeta?.featuredImage?.sizes ||
        data.featuredImage?.node?.sizes ||
        '',
      sourceUrl:
        // data?.caseStudyMeta?.featuredImage?.sourceUrl ||
        data.featuredImage?.node?.sourceUrl || '',
      altText:
        data?.caseStudyMeta?.featuredImage?.altText ||
        data.featuredImage?.node?.altText ||
        '',
    },
    products: data.caseStudyProducts?.nodes || [],
    title: data.title,
    excerpt: data.excerpt,
    href: data.uri,
    background: '#ffffff',
  };
};
const formatOptionLabel = ({ label, icon }) => (
  <div className="flex items-center">
    {icon && (
      <div className="w-[24px] flex-shrink-0">
        <img src={icon} alt={label} />
      </div>
    )}

    <div className={icon ? 'ml-[10px]' : ''}>{label}</div>
  </div>
);

function CaseStudyExploreWrapper({ caseStudies, productOptions }) {
  const {
    data,
    setProduct,
    // setType,
    setSearch,
    hasMore,
    loadMore,
  } = useCaseStudy({
    caseStudies,
    shorts: [],
    videos: [],
  });
  // console.log({ caseStudies, productOptions, data });
  return (
    <section className="relative p-section-2 bg-cf-light-grey case-study-explore">
      <div className="container relative">
        <div className="text-shs md:text-2lg font-heading font-semibold mb-6 md:mb-4 explore-heading">
          Explore all case studies
        </div>
      </div>
      <div className="flex flex-col-reverse md:flex-row justify-end  bg-cf-light-grey relative z-50 md:sticky top-0 container sticky-container">
        <div className="flex">
          <Select
            className="md:mr-6 w-full md:w-auto"
            baseClass="md:min-w-[280px]"
            formatOptionLabel={formatOptionLabel}
            placeholder="Product Used"
            options={productOptions}
            isClearable
            onChange={setProduct}
          />
          {/* <Select
            className="w-full md:w-auto"
            placeholder="Content Type"
            options={caseStudyOptions}
            onChange={setType}
          /> */}
        </div>
        <div className="mb-3 md:mt-0">
          <RoundedTextInput
            className="md:min-w-[290px]"
            placeholder="Search"
            clearEnabled
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>
      </div>
      <div className="container">
        {data.length === 0 ? (
          <div className="mt-[48px] md:mt-[128px] text-center">
            <div className="text-3md font-semibold">No Results Found!</div>
          </div>
        ) : (
          <div className="md:mx-[-19px] mt-[48px] md:mt-[60px] gap-x-0 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
            {data.map((d) => (
              <CaseStudyCard key={d.id} {...d} />
            ))}
          </div>
        )}
        {hasMore && (
          <div className="text-center">
            <button
              type="button"
              className="button button-green md:px-[40px] w-full md:w-auto bg-cf-green"
              tabIndex={0}
              onClick={loadMore}>
              Load more
            </button>
          </div>
        )}
      </div>
    </section>
  );
}

CaseStudyExploreWrapper.defaultProps = {
  caseStudies: [],
  shorts: [],
  videos: [],
  productOptions: [],
};
CaseStudyExploreWrapper.propTypes = {
  caseStudies: PropTypes.arrayOf(CaseStudyCardType),
  shorts: PropTypes.arrayOf(CaseStudyCardType),
  videos: PropTypes.arrayOf(CaseStudyCardType),
  productOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
      icon: PropTypes.string,
    }),
  ),
};
const CaseStudyExplore = (rest) => (
  <StaticQuery
    query={graphql`
      query caseStudyExplore {
        allWpCaseStudyProduct {
          nodes {
            name
            slug
            caseStudyProductMeta {
              image {
                altText
                sourceUrl
              }
            }
          }
        }
        allWpCaseStudy(sort: { fields: date, order: DESC }) {
          nodes {
            slug
            caseStudyMeta {
              featuredImage {
                srcSet
                sizes
                sourceUrl
                altText
              }
            }
            caseStudyProducts {
              nodes {
                slug
                name
                link
              }
            }
            featuredImage {
              node {
                srcSet
                sizes
                sourceUrl
                altText
              }
            }
            title
            uri
            excerpt
          }
        }
      }
    `}
    render={({ allWpCaseStudy, allWpCaseStudyProduct }) => (
      <CaseStudyExploreWrapper
        caseStudies={allWpCaseStudy.nodes.map((d, i) =>
          transform({ ...d, id: `cs-${i}` }, 'caseStudy'),
        )}
        productOptions={[
          { label: 'All', value: '', icon: null },
          ...allWpCaseStudyProduct.nodes.map((p) => ({
            label: p.name,
            value: p.slug,
            icon: p.caseStudyProductMeta?.image?.sourceUrl || null,
          })),
        ]}
        {...rest}
      />
    )}
  />
);

export default CaseStudyExplore;
