import { useState, useEffect } from 'react';

const PAGE_SIZE = 15;

const createMasanory = (filters, caseStudies) => {
  // const l = [
  //   ...caseStudies.map((d, i) =>
  //     transform({ ...d, id: `cs-${i}` }, 'caseStudy'),
  //   ),
  //   ...videos.map((d, i) => transform({ ...d, id: `v-${i}` }, 'video')),
  // ].sort(() => 0.5 - Math.random());
  // const s = shorts.map((d, i) => transform({ ...d, id: `s-${i}` }, 'short'));
  // const l = [...caseStudies];
  // const s = [];
  // const cards = [];
  // while (l.length || s.length) {
  //   if (l.length) cards.push(l.pop());
  //   if (s.length) cards.push(s.pop());
  // }
  const cards = [...caseStudies];
  return cards
    .filter(
      (c) =>
        filters.product === '' ||
        c.products.map((p) => p.slug).includes(filters.product),
    )
    .filter((c) =>
      c.title.toLowerCase().includes(filters.search.toLowerCase()),
    );
};

const useCaseStudy = ({ caseStudies = [], videos = [], shorts = [] }) => {
  const [filters, setFilters] = useState({
    product: '',
    type: '',
    search: '',
  });
  const [page, setPage] = useState(1);

  const data = createMasanory(filters, caseStudies, videos, shorts);

  useEffect(() => {
    setPage(1);
  }, [filters]);
  return {
    data: data.slice(0, PAGE_SIZE * page),
    filters,
    setProduct: (p) => {
      setFilters((prev) => ({ ...prev, product: !p ? '' : p.value }));
    },
    setType: (t) => setFilters((prev) => ({ ...prev, type: t.value })),
    setSearch: (s) => setFilters((prev) => ({ ...prev, search: s })),
    loadMore: () => setPage((p) => p + 1),
    hasMore: page * PAGE_SIZE < data.length,
  };
};

export default useCaseStudy;
