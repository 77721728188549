import React from 'react';
import CaseStudyExplore from '../components/CaseStudyExplore';
import GetStarted from '../components/GetStarted';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import TransparentVideo from '../components/TransparentVideo/TransparentVideo';
import '../styles/case-study-layout.scss';

const CaseStudyPage = (props) => {
  return (
    <Layout
      lightHeader
      {...props}
      signInLink="https://merchant.cashfree.com/merchants/login?source-action=international%20wire%20transfer&action=Sign%20In&button-id=SignIn_Navbar"
      createAccountLink="https://merchant.cashfree.com/merchants/signup?source-action=international%20wire%20transfer&action=Sign%20Up&button-id=StartNow_Navbar">
      <SEO
        title="Cashfree Payments - Case Studies & Customer Success Stories"
        description="Know how Cashfree Payments helped clients achieve their goals with our collection of case studies and customer success stories. Learn how our Payments solutions can benefit your business today"
        // url=""
        keywords={[
          'case studies',
          'customer success stories',
          'client success',
          'business results',
          'customer satisfaction',
          'project outcomes.',
        ]}
      />

      <section className="pt-[110px] md:pt-[140px] pb-[0] lg:pb-[128px] bg-cf-dark  casestudy-layout-hero text-white overflow-hidden relative">
        <div className="container position-relative gradient-bg">
          <div className="flex flex-wrap flex-col lg:flex-row md:justify-between lg:w-[130%] mb-[-8%] lg:mb-0">
            <div className="relative z-[101] w-full lg:w-1/2 lg:self-center">
              <div className="text-sm font-semibold text-white pl-5 mb-[10px] uppercase relative before:bg-cf-green before:h-[10px] before:w-[10px] before:absolute before:top-[4px] before:left-[4px] font-body">
                Customer Stories
              </div>
              <div className="mb-5 text-xl md:text-2xl font-semibold max-w-[500px] font-heading">
                Revolutionizing Payment Process
              </div>
              <div className="mb-8 max-w-[580px] text-[16px] leading-[24px] md:text-2.5md text-white">
                3,00,000+ businesses trust us with their payment needs
              </div>
            </div>
            <div className="w-[205%] left-[-53%] md:w-full md:left-0 lg:w-1/2 flex items-center relative pt-[48px] md:pt-0 ">
              <TransparentVideo
                containerClass="lg:left-[-35%] hero-video"
                className="relative z-10"
                poster="/img/casestudy/poster.png"
                width="1499"
                height="850"
                mp4Src="/img/casestudy/hero-video.mp4"
                webmSrc="/img/casestudy/hero-video.webm"
              />
            </div>
          </div>
        </div>
      </section>
      <CaseStudyExplore />
      <GetStarted startNowLink="!#" getInTouchLink="!#" />
    </Layout>
  );
};

export default CaseStudyPage;
