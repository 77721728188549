import React from 'react';
import ReactSelect from 'react-select';
import PropTypes from 'prop-types';

const Select = ({ className, options, baseClass, isClearable, ...props }) => (
  <ReactSelect
    className={`${baseClass} ${className} text-2.5sm md:text-[16px] md:leading-[24px] font-medium font-body`}
    options={options}
    isOptionDisabled={(o) => o?.disabled}
    isClearable={isClearable}
    classNames={{
      control: (state) =>
        `select-controls ${state.isFocused ? '!border-cf-green' : ''}`,
      valueContainer: () => 'select-value-container',
      menuList: () => `select-menulist`,
      option: () => 'select-option',
    }}
    styles={{
      dropdownIndicator: (base, state) => ({
        ...base,
        transition: 'all .2s ease',
        transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : null,
      }),
      placeholder: (base) => ({
        ...base,
        whiteSpace: 'nowrap',
        fontWeight: 500,
        color: '#240253',
      }),
      control: (base) => ({
        ...base,
        borderRadius: 8,
        minHeight: 44.6,
        boxShadow: 'none',
        borderColor: 'none',
      }),
      menuList: (base) => ({
        ...base,
        padding: '4px',
      }),
      valueContainer: (base) => ({
        ...base,
        color: '#240253',
      }),
      option: (base, state) => {
        return {
          ...base,
          // eslint-disable-next-line no-nested-ternary
          backgroundColor:
            state.isSelected || state.isFocused
              ? 'rgba(105, 51, 211, 0.1)'
              : base.backgroundColor,
          borderBottomStyle: 'solid',
          paddingLeft: 8,
          ':active': {
            color: 'rgba(#6933D3, 0.8)',
          },

          color: state.isSelected ? '#6933D3' : base.color,
          fontWeight: state.isSelected ? 500 : 400,
        };
      },
    }}
    {...props}
    // components={{
    //   IndicatorSeparator: () => null,
    // }}
  />
);
Select.propTypes = {
  className: PropTypes.string,
  baseClass: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    }),
  ),
  isClearable: PropTypes.bool,
};
Select.defaultProps = {
  className: '',
  isClearable: false,
  baseClass: 'md:min-w-[216px]',
  options: [],
};
export default Select;
