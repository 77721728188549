/* eslint-disable react/prop-types */
import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';

function RoundedTextInput({
  className,
  id,
  label,
  padding,
  onChange,
  clearEnabled,
  onClear,
  ...props
}) {
  const inputRef = useRef();
  const [clear, setClear] = useState(false);

  const change = (e) => {
    setClear(!!inputRef.current.value.length);
    onChange(e);
  };
  const clearInput = () => {
    inputRef.current.value = '';
    onChange({ target: { value: '' } });
    setClear(false);
  };
  return (
    <>
      {label.length > 0 && (
        <label className="sr-only" htmlFor={id}>
          {label}
        </label>
      )}
      <div className="w-full relative">
        <div className="absolute inset-y-0 left-0 flex items-center pl-[18px] md:pl-[11px] pointer-events-none">
          <svg
            width="24"
            height="24"
            className="w-[16px] md:w-[24px]"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M11 19C15.4183 19 19 15.4183 19 11C19 6.58172 15.4183 3 11 3C6.58172 3 3 6.58172 3 11C3 15.4183 6.58172 19 11 19Z"
              stroke="black"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M20.9999 20.9999L16.6499 16.6499"
              stroke="black"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
        <input
          id={id}
          className={`${className} border border-stroke text-2sm md:text-[16px] md:leading-[24px] rounded-lg focus:ring-cf-green focus:border-cf-green block w-full pl-[42px] md:pl-[44px] md:h-[46px] ${
            clearEnabled ? 'pr-[24px] md:pr-[24px]' : ''
          } ${padding}`}
          type="text"
          onChange={change}
          ref={inputRef}
          {...props}
        />
        {clearEnabled && clear && (
          <button
            className="absolute right-2 top-[14px] md:top-[15px]"
            type="button"
            onClick={clearInput}
            aria-label="clear input">
            <svg
              className="svg-close"
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="none">
              <path
                stroke="#000"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.333"
                d="m12 4-8 8M4 4l8 8"
              />
            </svg>
          </button>
        )}
      </div>
    </>
  );
}
RoundedTextInput.defaultProps = {
  className: '',
  padding: 'py-[8.7px] px-[16px] md:p-[16px] md:py-[11px]',
  label: '',
  id: null,
  clearEnabled: false,
  onChange: () => {},
};
RoundedTextInput.propTypes = {
  className: PropTypes.string,
  padding: PropTypes.string,
  label: PropTypes.string,
  id: PropTypes.string,
  clearEnabled: PropTypes.bool,
  onChange: PropTypes.func,
};
export default RoundedTextInput;
