import React from 'react';
import PropTypes from 'prop-types';
import './SelectInput.scss';

function SelectInput({
  className,
  id: selectId,
  label: selectLabel,
  placeholder,
  options,
  ...props
}) {
  return (
    <>
      {selectLabel.length > 0 && (
        <label className="sr-only" htmlFor={selectId}>
          {selectLabel}
        </label>
      )}
      <select
        placeholder={placeholder}
        id={selectId}
        className={`select-input inline-block appearance-none border border-cf-stroke text-2sm rounded focus:ring-cf-green focus:border-cf-green px-3 py-[9px] md:py-[16px]  w-full ${className}`}
        {...props}>
        <option value="">{placeholder}</option>
        {options.map(({ id, label, value }) => (
          <option value={value} key={id}>
            {label}
          </option>
        ))}
      </select>
    </>
  );
}
SelectInput.defaultProps = {
  className: '',
  label: '',
  id: null,
  placeholder: 'Choose',
  options: [],
};
SelectInput.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  id: PropTypes.string,
  placeholder: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.node,
      ]),
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  ),
};
export default SelectInput;
