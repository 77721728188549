/* eslint-disable no-unused-vars */
import React from 'react';
import { CaseStudyCardType } from '../../utils/prop-types';

const CaseStudyCard = ({ dataType, media, title, excerpt, href }) => {
  return (
    <article className="md:px-[19px] pb-4 md:pb-[40px] inline-block w-full">
      <div className="bg-white transition-all duration-300 md:hover:shadow-card rounded overflow-hidden">
        <div className="w-full bg-slate-300">
          {media?.sourceUrl !== '' && (
            <a href={href} className="relative w-full block">
              <img
                className="object-cover object-center w-full h-full"
                src={media?.sourceUrl}
                alt={media?.altText}
                sizes={media?.sizes}
                srcSet={media?.srcSet}
              />
            </a>
          )}
        </div>
        <div className="p-6">
          <h3 className="font-body line-clamp-3 text-[16px] leading-6 md:text-2.5md font-semibold md:min-h-[85px] overflow-hidden">
            <a href={href}>{title}</a>
          </h3>
          {dataType === 'short' && <p>{excerpt}</p>}
          <a
            href={href}
            className="button button-unstyled btn mt-5 p-0 !text-cf-green group left-[83px] !right-auto">
            Learn More <span className="chevron chevron-green" />
          </a>
        </div>
      </div>
    </article>
  );
};

CaseStudyCard.propTypes = CaseStudyCardType;

// CaseStudyCard.defaultProps = {
//   background: '#ffffff',
// };
export default CaseStudyCard;
